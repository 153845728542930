<template>
  <file-pond
    name="file"
    ref="pond"
    class-name="my-pond"
    :allow-multiple="allowMultiple"
    :allow-reorder="allowMultiple"
    :accepted-file-types="acceptedFileTypes"
    :files="filesInternal"
    v-on:init="handleFilePondInit"
    v-bind:server="serverConfig"
    v-on:processfile="handleFileProcessed"
    v-on:processfiles="handleFilesProcessed"
    v-on:removefile="handleFileRemoved"
    v-on:updatefiles="handleFilesUpdated"
    v-on:reorderfiles="handleFilesReorder"
    allowImageTransform
    :imageEditor="editor"
    :labelIdle="label ? label : 'Drag & Drop your files or Browse'"
    credits="false"
  />
</template>

<script>
import vueFilePond from "vue-filepond";
import axios from "axios";

// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEditor from "filepond-plugin-image-editor";

import { PinturaEditor } from "vue-pintura";

// Import pintura
import {
  // editor
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  locale_en_gb,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_filter,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,

  // filepond
  openEditor,
  processImage,
  createDefaultImageOrienter,
  legacyDataToImageState,
} from "pintura";

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageEditor,
  FilePondPluginImageTransform
);

export default {
  name: "FilePicker",
  props: [
    "files",
    "update",
    "imageOnly",
    "allowMultiple",
    "label",
    "acceptedType",
  ],
  mounted() {
    console.log("mounted");
  },
  computed: {
    acceptedFileTypes: function () {
      if (this.acceptedType) return this.acceptedType;
      return this.imageOnly
        ? ["image/*"]
        : ["image/*", "application/pdf", "video/mp4"];
    },
  },
  data() {
    return {
      filesInternal: this.files.map((file) => ({
        source: file,
        options: {
          type: "local",
        },
      })),
      serverConfig: {
        url: `${process.env.VUE_APP_PAIZA_ENDPOINT}`,
        process: {
          url: "/files",
          method: "POST",
          withCredentials: false,
          headers: {
            authorization: `Bearer ${this.$auth.token()}`,
          },
          timeout: 30000,
          onload: null,
          onerror: null,
          ondata: (formdata) => {
            formdata.append("meta", "{}");
            return formdata;
          },
        },
        load: (source, load, error, progress, abort, headers) => {
          console.log(source);
          this.$http
            .get(`/files/${source}`)
            .then(({ data }) => {
              return axios({
                method: "get",
                url: data.url,
                responseType: "blob",
                transformRequest: (data, headers) => {
                  delete headers.common;
                  return data;
                },
              }).then(({ data }) => {
                progress(true, 0, 1024);
                load(data);
              });
            })
            .catch((err) => {
              error(err.message);
            });
          return {
            abort: () => {
              abort();
            },
          };
        },
        remove: (source, load, error) => {
              load();
        },
      },
      editor: {
        // map legacy data objects to new imageState objects
        legacyDataToImageState: legacyDataToImageState,

        // used to create the editor, receives editor configuration, should return an editor instance
        createEditor: openEditor,

        // Required, used for reading the image data
        imageReader: [createDefaultImageReader],

        imageWriter: [
          createDefaultImageWriter,
          {
            targetSize: {
              width: 1480,
              height: 1028,
              fit: "contain",
              upscale: false,
            },
            copyImageHead: false,
            mimeType: "image/jpeg",
            quality: 0.9,
          },
        ],

        // used to generate poster images, runs an editor in the background
        imageProcessor: processImage,

        // editor options
        editorOptions: {
          imageOrienter: createDefaultImageOrienter(),
          ...plugin_finetune_defaults,
          ...plugin_filter_defaults,
          ...markup_editor_defaults,
          locale: {
            ...locale_en_gb,
            ...plugin_crop_locale_en_gb,
            ...plugin_finetune_locale_en_gb,
            ...plugin_filter_locale_en_gb,
            ...plugin_annotate_locale_en_gb,
            ...markup_editor_locale_en_gb,
          },
          cropSelectPresetOptions: [
            [
              "Crop",
              [
                [undefined, "Custom"],
                [1, "Square"],
                [4 / 3, "Landscape"],
                [3 / 4, "Portrait"],
              ],
            ],
            [
              "Size",
              [
                [[180, 180], "Profile Picture"],
                [[1200, 600], "Header Image"],
                [[800, 400], "Timeline Photo"],
              ],
            ],
          ],
        },
      },
    };
  },
  methods: {
    handleFilePondInit: function () {
      console.log("FilePond has initialized");
    },
    handleFileProcessed: function (error, file) {
      if (error) {
        console.log(error);
      }
      const parsedFile = JSON.parse(file.serverId);
      if (parsedFile) {
        this.filesInternal.push({
          source: parsedFile.id,
          options: {
            type: "local",
          },
        });
      }
    },
    handleFilesProcessed: function () {
      console.log("processed files");
    },
    handleFileRemoved: function (error, file) {
      console.log("removed file");
      this.filesInternal = this.filesInternal.filter(
        (f) => f.source !== file.source
      );
    },
    handleFilesUpdated: function (files) {
      console.log("updated files");

      const filesOutput = this.filesInternal.map((file) => {
        return file.source;
      });

      if (this.allowMultiple) {
        this.$emit("update", filesOutput);
      } else {
        if (filesOutput.length === 0) {
          this.$emit("update", "");
          return
        }
        this.$emit("update", filesOutput[0]);
      }
    },
    handleFilesReorder: function (files, origin, target) {
      var element = this.filesInternal[origin];
      const newFilesInternal = [...this.filesInternal];
      newFilesInternal.splice(origin, 1);
      newFilesInternal.splice(target, 0, element);
      this.filesInternal = newFilesInternal;

      const filesOutput = this.filesInternal.map((file) => {
        return file.source;
      });
      if (this.allowMultiple) {
        this.$emit("update", filesOutput);
      } else {
        if (filesOutput.length === 0) {
          this.$emit("update", "");
          return
        }
        this.$emit("update", filesOutput[0]);
      }
    }
  },
  components: {
    FilePond,
    PinturaEditor,
  },
};
</script>

<style>
@import "../../../node_modules/pintura/pintura.css";

.pintura-editor {
  box-shadow: 0 0 0 1px #eee;
  --color-background: 0, 0, 0;
  --color-foreground: 255, 255, 255;
}
.filepond--action-edit-item-alt {
  color: white !important;
  font-size: 1.3rem !important;
}

.filepond--file-info .filepond--file-info-main {
  color: transparent;
  height: 0.4rem;
  font-size: 2px;
}

</style>
